$(document).on('turbolinks:load', function() {
// jQuery(function() {
  var cities, districts;
  // $('##person_city_id').parent().hide();
  // cities = $('#person_city_id').html();
  // districts = $('#person_district_id').html();
// alert("inside jQuery")

  $.get('/cities.txt', function(data) { cities = data;}, 'text');
  $.get('/districts.txt', function(data) { districts = data;}, 'text');

  $('#person_region_id').change(function() {
    var region, escaped_region, options;
    region = $('#person_region_id :selected').text();
    escaped_region = region.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    options = '<option value="">Provincia...</option>\n';
    opt = $(districts).filter(`optgroup[label='${escaped_region}']`).html();
    if (opt) {
      options = '<option value="">Provincia...</option>\n' + opt;
    }
    $('#person_district_id').html(options);
    $('#person_city_id').html('<option value="">Comune...</option>');

    $('#person_city_id').parent().show();
    return $('#person_district_id').parent().show();
  });

  $('#person_district_id').change(function() {
    var district, escaped_district, options;
    district = $('#person_district_id :selected').text();
    escaped_district = district.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    options = '<option value="">Comune...</option>\n'
    opt = $(cities).filter(`optgroup[label='${escaped_district}']`).html();
    if (opt) {
      options = '<option value="">Comune...</option>\n' + opt;
    }
    $('#person_city_id').html(options);
    return $('#person_city_id').parent().show();
  });

  $('#person_church_attributes_region_id').change(function() {
    var region, escaped_region, options;
    region = $('#person_church_attributes_region_id :selected').text();
    escaped_region = region.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    options = '<option value="">Provincia...</option>\n';
    opt = $(districts).filter(`optgroup[label='${escaped_region}']`).html();
    if (opt) {
      options = '<option value="">Provincia...</option>\n' + opt;
    }
    $('#person_church_attributes_district_id').html(options);
    $('#person_church_attributes_city_id').html('<option value="">Comune...</option>');

    $('#person_church_attributes_city_id').parent().show();
    return $('#person_church_attributes_district_id').parent().show();
  });

  $('#person_church_attributes_district_id').change(function() {
    var district, escaped_district, options;
    district = $('#person_church_attributes_district_id :selected').text();
    escaped_district = district.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    options = '<option value="">Comune...</option>\n'
    opt = $(cities).filter(`optgroup[label='${escaped_district}']`).html();
    if (opt) {
      options = '<option value="">Comune...</option>\n' + opt;
    }
    $('#person_church_attributes_city_id').html(options);
    return $('#person_church_attributes_city_id').parent().show();
  });

  $('#person_residence_attributes_district_id').change(function() {
    var district, escaped_district, options;
    district = $('#person_residence_attributes_district_id :selected').text();
    escaped_district = district.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    options = '<option value="">Comune...</option>\n'
    opt = $(cities).filter(`optgroup[label='${escaped_district}']`).html();
    if (opt) {
      options = '<option value="">Comune...</option>\n' + opt;
    }
      // options = "<option value=\"\"></option>\n"+options;
      $('#person_residence_attributes_city_id').html(options);
      return $('#person_residence_attributes_city_id').parent().show();

  });

});

// document.addEventListener("turbolinks:load", function() {
//     console.log('It works on each visit!');
//     alert("It works");
// });
